<template>
  <div class="card">
    <div class="">
      <div class="gap-3 tw-flex tw-items-center tw-justify-between tw-mb-4">
        <a
          href="#"
          @click.prevent="back"
          class="block text-lg tw-font-semibold tw-text-blue-600 tw-py-2"
        >
          <i class="mr-2 pi pi-arrow-left"></i>
          Kembali
        </a>
        <div class="gap-3 tw-flex tw-items-center tw-justify-between">
          <router-link
            :to="{ name: 'salon' }"
            class="block text-lg tw-font-semibold tw-text-blue-600 tw-px-4 tw-py-2"
          >
            Hapus
          </router-link>
        </div>
      </div>
      <h1 class="tw-text-3xl tw-font-bold">Edit Salon</h1>
    </div>

    <hr class="tw-my-5" />

    <div class="grid">
      <Form
        @submit="updateSalon"
        :initial-data="salon"
        :init-schedule="schedules"
      />
      <div class="col-12 md:col-5 tw-pl-10">
        <label
          class="tw-text-black tw-font-semibold inter-medium tw-mb-2 block"
        >
          Banner Utama <span class="tw-text-red-700">*</span>
        </label>
        <FormKit
          v-model="data"
          :type="uploadImage"
          name="image"
          validation="required"
          validation-visibility="dirty"
          :classes="{ ...defaultFormikClass, label: 'tw-font-semibold' }"
          :upload="updateServiceImage"
        />
        <div>
          <img :src="salon.image" />
        </div>
      </div>
    </div>
  </div>

  <BankAccountList :salon-name="salon.name" />
  <BannerList :salon-id="route.params.id || salonId" />
</template>

<script setup>
import BankAccountList from "./bank-accounts/BankAccountList";
import BannerList from "./banners/BannerList";
import Form from "./Form";
import FormUploadImage from "@/components/form/FormUploadImage";
import { useSalonForm } from "@/composables/salon";
import { useRoute, useRouter } from "vue-router";
import { onBeforeMount, ref } from "vue";
import { useAuthRole } from "@/composables/auth";
import { createInput } from "@formkit/vue";
import { defaultFormikClass } from "@/constants/formik";

const {
  salon,
  getDetailSalon,
  update,
  getOperationalSchedules,
  schedules,
  postOperationalSchedules,
  putOperationalSchedules,
} = useSalonForm();
const { salonId, isAdminSalon } = useAuthRole();
const router = useRouter();
const route = useRoute();
const data = ref(null);

const uploadImage = createInput(FormUploadImage, {
  props: ["src", "upload"],
});

const { uploadMainBanner } = useSalonForm();

const updateServiceImage = async (data) => {
  await uploadMainBanner(route.params.id, data);
  await getDetailSalon(route.params.id || salonId.value);
};

const updateSalon = async (formData, schedule) => {
  schedule.salon_id = parseInt(route.params.id || salonId.value);
  await update(route.params.id || salonId.value, formData);

  if (schedule.id) {
    await putOperationalSchedules(
      route.params.id || salonId.value,
      schedule.id,
      schedule
    );
  } else {
    await postOperationalSchedules(schedule);
  }

  await router.push({
    name: isAdminSalon() ? "salon" : "detail-salon",
    params: isAdminSalon() ? null : { id: route.params.id },
  });
};

onBeforeMount(async () => {
  await getDetailSalon(route.params.id || salonId.value);
  await getOperationalSchedules(route.params.id || salonId.value);
  window.onbeforeunload = () => {
    return "Data yang anda masukkan akan hilang";
  };
});

const back = () => {
  if (isAdminSalon()) {
    router.push({ name: "salon" });
  } else {
    router.push({ name: "detail-salon", params: { id: route.params.id } });
  }
};
</script>
